import {gql} from "@apollo/client"

const HR_USER_EMPLOYERS_QUERY = gql`
  query HrUserEmployers($id: String!) {
    hrUserEmployers(user: $id, first: 200) {
      edges {
        node {
          hrEmployer {
            id
            name
          }
        }
      }
    }
  }
`

export { HR_USER_EMPLOYERS_QUERY }
